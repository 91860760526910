.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container for the webpage */
.container {
  position: relative;
  height: 100vh;
  width: 100vw;
  background: #0f172a;
  overflow: hidden;
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  padding: 20px;
  flex-direction: column; /* Added to ensure column layout */
  text-align: center;
}

/* Circle light effect */
.circle {
  position: absolute;
  width: 1000px;
  height: 1000px;
  background: radial-gradient(
    circle,
    rgba(15, 23, 42, 1) 0%, 
    rgba(15, 23, 42, 0.5) 20%, 
    rgba(15, 23, 42, 0.2) 30%, 
    rgba(15, 23, 42, 0) 100%
  );
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  filter: blur(40px);
  mix-blend-mode: screen;
  /*animation: pulse 3s infinite ease-in-out;*/
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 0.6;
  }
}

/* Name Styling */
.name {
  font-size: 3rem;
  margin-bottom: 30px;
  color: rgba(173, 216, 230, 0.9); /* Light bluish white color */
  text-align: center;
}

/* Profile Image */
.profile-image-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

/* Profile Image */
.profile-image-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  /*filter: grayscale(100%) sepia(100%) hue-rotate(120deg) saturate(200%) brightness(0.6); /* Stronger greenish filter */
  transition: all 0.3s ease-in-out;
}

.profile-image:hover {
  filter: none; /* Removes the filter on hover */
  box-shadow: 0 0 15px #10b981; /* Neon green glow on hover, matching icon hover color */
}

/* About Me Paragraph */
.about {
  font-size: 1.2rem;
  margin: 20px;
  color: rgba(200, 216, 230, 0.6); /* Light bluish white color */
  line-height: 1.5;
  max-width: 600px;
  text-align: center;
}

.about .neon-green {
  color: #10b981;/* Green color matching the icon hover */
}

/* Social Links */
.social-icons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icons a {
  text-decoration: none;
  font-size: 2rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-icons .icon {
  width: 35px;
  height: 35px;
  color: white;
  transition: color 0.3s ease, transform 0.3s ease;
}

.social-icons .icon:hover {
  color: #10b981; /* Neon green on hover */
  /*transform: scale(1.2); /* Zoom effect */
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .name {
    font-size: 2.5rem; /* Adjust the size for smaller screens */
  }

  .profile-image {
    width: 120px;
    height: 120px;
  }

  .social-icons .icon {
    font-size: 1.5rem; /* Adjust size on smaller screens */
  }
}
